<template>
    <!--编辑页与详情页相同-->
    <div class="AllocateDetail" style="background-color: #f2f2f2; margin-top: 8px">
        <el-card shadow="never">
            <el-page-header @back="goBack" />
        </el-card>
        <el-card shadow="never" style="margin-top: 8px">
            <div>
                <el-form :id="formId" ref="form" label-width="80px" width="auto" size="small">
                    <el-row>
                        <el-col :span="8">
                            <el-form-item label="创建人">
                                <el-input readonly v-model="form.creator" />
                            </el-form-item>
                        </el-col>
                        <el-col :span="8">
                            <el-form-item label="创建日期">
                                <el-input readonly v-model="form.createTime" />
                            </el-form-item>
                        </el-col>
                        <el-col :span="8">
                            <el-form-item label="操作人">
                                <el-input readonly v-model="form.updater" />
                            </el-form-item>
                        </el-col>
                    </el-row>
                    <el-row>
                        <el-col :span="8">
                            <el-form-item label="操作时间">
                                <el-input readonly v-model="form.createTime" />
                            </el-form-item>
                        </el-col>
                        <el-col :span="8">
                            <el-form-item label="调出机构">
                                <el-input readonly v-model="form.outDeptName" />
                            </el-form-item>
                        </el-col>
                        <el-col :span="8">
                            <el-form-item label="调入机构">
                                <el-input readonly v-model="form.deptName" />
                            </el-form-item>
                        </el-col>
                    </el-row>
                    <el-row>
                        <el-col :span="8">
                            <el-form-item label="状态">
                                <el-input readonly v-model="form.stockStatus" />
                            </el-form-item>
                        </el-col>
                    </el-row>
                </el-form>
            </div>
        </el-card>
        <el-card shadow="never" style="margin-top: 8px">
            <div style="height: 32px">
                <el-button style="float: right; margin-top: 3px" size="mini" @click="handlePrint">打印</el-button>
            </div>
        </el-card>
        <el-card shadow="never" style="margin-top: 8px">
            <el-table
                border
                stripe
                style="width: 100%"
                v-loading="tableLoading"
                :data="tableData"
                size="mini"
                show-summary
                :highlight-current-row="true"
                max-height="440"
                :id="tableId"
            >
                <el-table-column label="序号" type="index" width="60" align="center" fixed="left" />
                <el-table-column prop="sku.name" label="商品名称" width="140" />
                <el-table-column prop="sku.bars" label="条码" width="120">
                    <template slot-scope="scope">
                        <span v-for="b in scope.row.sku.bars" :key="b">{{ b }}</span>
                    </template>
                </el-table-column>
                <el-table-column prop="sku.type" label="商品类型" width="100">
                    <template slot-scope="scope">
                        <span>{{ scope.row.sku.type | goodsType }}</span>
                    </template>
                </el-table-column>
                <el-table-column prop="sku.specs" label="规格" width="100" />
                <el-table-column prop="sku.category" label="类目" width="100" />
                <el-table-column prop="sku.unit" label="单位" width="100" />
                <el-table-column prop="sku.brand" label="品牌" width="100" />
                <el-table-column prop="prices" label="单价" width="100" v-if="showInPrice()" />
                <el-table-column prop="counts" label="数量" width="100" />
                <el-table-column prop="money" label="金额" width="100" v-if="showInPrice()">
                    <template slot-scope="scope">
                        <span>{{ scope.row.money }}</span>
                    </template>
                </el-table-column>
            </el-table>
        </el-card>
    </div>
</template>
<script>
import UrlUtils from 'js/UrlUtils';

export default {
    name: 'AllocateInDetail',
    props: ['form'],
    data() {
        return {
            url: {
                goods: '/goods/goods/list_c',
            },
            codes: [],
            tableData: [],
            outDeptName: '',
            tableLoading: true,
            //id标识
            formId: 'form',
            tableId: 'table',
        };
    },
    mounted() {
        this.codes = this.form.goodsCode;
        if (this.form.stockStatus == 0) {
            this.form.stockStatus = '待入库';
        } else {
            this.form.stockStatus = '已入库';
        }
        this.handleQuery();
    },
    methods: {
        handlePrint() {
            this.printIdSelectorHtml(
                [
                    { id: this.formId, type: 'form' },
                    { id: this.tableId, type: 'table' },
                ],
                this.$route.meta.title || ''
            );
        },
        handleQuery() {
            UrlUtils.QueryGoodsInfoByCodes(this, this.url.goods, { codes: this.codes }, (data) => {
                data.forEach((v, i) => {
                    v.prices = this.fmtMFour(this.form.prices[i]);
                    v.counts = this.form.counts[i];
                    v.money = this.fmtMFour(this.decimal(this.form.prices[i]).mul(v.counts).toNumber());
                });
                this.tableData = data;
            });
        },
    },
};
</script>

<style scoped>
.el-input {
    width: auto;
}

.el-form-item {
    width: auto;
}
</style>
